@charset "UTF-8";
/* ----------------------------------------------------------------
変数の設定項目
-----------------------------------------------------------------*/

/**
 * 1:パスの設定
 * 2:font-familyの設定
 * 3:font-size・line-heightの設定
 * 4:colorの設定
 * 5:マージンの設定
 * 6:componentパーツ「.c-grid」の余白の設定
 * 7:componentパーツ「.c-container, .c-inner」の幅設定
 * 8:ブレイクポイントの設定
 * 9:フォームの設定
 * 適用範囲等の詳しい説明は下記URLからご確認ください。
 * http://
 */

/* 1:パスの設定
-----------------------------------------------------------------*/
$src: "../";

/* 2:font-familyの設定
-----------------------------------------------------------------*/
/* サイト全体のfont-familyの設定 */
$fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans JP", "Hiragino Sans", "Noto Sans CJK JP", "Original Yu Gothic", "Yu Gothic", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans Emoji";

/* イレギュラーなfont-familyの設定 */

/* GoogleFontの設定 */

/* AdobeFontの設定 */

/* 3:font-size・line-heightの設定
-----------------------------------------------------------------*/
 /* フォントサイズの設定【単位:rem】 */
$fsBase: 1.4; //base
$fsBaseSp: 1.2; //sp base

/* 行間の設定 */
$lhBase: 1.8; //base
$lhBaseSp: 1.5; //sp base

/* 4:colorの設定
-----------------------------------------------------------------*/
/* リンクのカラー設定 */
$clrLink: #000; //標準のリンク色
$clrLinkHover: #ca1f7e; //hover時のリンク色

/* 7:componentパーツ「.c-container, .c-inner」の幅設定
-----------------------------------------------------------------*/
$containerWidthBase: 1200px;//ベース幅
$containerWidthSmall: 0px;//最小幅
$containerWidthLarge: 1200px;//最大幅
$containerWrap: 1200px;//タブレットでの表示サイズ

/* 8:ブレイクポイントの設定
-----------------------------------------------------------------*/
$queryPc: "screen and (min-width: 1200px)";//PC
$queryPcTab: "screen and (min-width:768px)";//PC&TAB
$queryTab: "screen and (min-width: 768px) and (max-width:1199px)";//TAB
$querySp: "screen and (max-width: 767px)";//SP