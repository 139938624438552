@charset "UTF-8";
/*----------------------------------------------------------------
レイアウトのコンポーネント
-----------------------------------------------------------------*/
.c-wrap {
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.c-container {
	width: 100%;
}