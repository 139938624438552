@charset "UTF-8";
/* ----------------------------------------------------------------
layoutのスタイル
-----------------------------------------------------------------*/
.c-inner {
	width: 100%;
	max-width: 980px;
	margin: 0 auto;
	padding: 20px 20px;
	@media #{$querySp} {
		padding: 10px 10px;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}


