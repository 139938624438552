@charset "UTF-8";
/* ----------------------------------------------------------------
base
-----------------------------------------------------------------*/
@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

//base setting
html {
	font-size: 62.5%;
    width: 100%;
    height: 100%;
	-webkit-text-size-adjust: none;
 	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

body {
	width: 100%;
	height: 100%;
	font-family: $fontFamily;
	font-size: $fsBase + rem;
	line-height: $lhBase;
	font-weight: 400;
	color: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  	font-smoothing: antialiased;
	@media #{$querySp} {
		font-size: $fsBaseSp + rem;
		line-height: $lhBaseSp;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

a {
	color: $clrLink;
	text-decoration: none;
    cursor: pointer;
	&:link,&:visited,&:hover,&:active {
		outline: none;
	}
}