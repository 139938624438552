@charset "UTF-8";
/* ----------------------------------------------------------------
headerのスタイル
-----------------------------------------------------------------*/
body.overflow-hidden {
	/* when primary navigation is visible, the content in the background won't scroll */
	overflow: hidden;
}

.h-menuWrap {
	@media #{$querySp} {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100px;
		z-index: 200;
	}
	@media #{$queryPcTab} {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 200;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100px;
		padding: 0 32px;
		@include trans($duration: .3s,$delay: 0s)
	}
}

.h-headWrap {
	@media #{$querySp} {
		position: absolute;
		top: 0;
		left: 0;
		background: transparent;
		height: 64px;
		width: 100%;
		z-index: 200;
		/* Force Hardware Acceleration in WebKit */
		transform: translate3d(0, 0, 0);
		backface-visibility: hidden;
		@include trans($duration: .3s,$delay: 0s)
	}
	@media #{$queryPcTab} {
		display: flex;
		align-items: center;
	}
}

.h-logo {
	@media #{$querySp} {
		display: block;
		position: absolute;
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
		left: 30px;
		@include trans($duration: .3s,$delay: 0s)
	}
	@media #{$queryPcTab} {
	}
}

.h-logo img {
	display: block;
	@media #{$querySp} {
		width: 125px;
		height: auto;
	}
	@media #{$queryPcTab} {
	}
}

/*----- クラス追加 -----*/
.h-menuWrap.is-fixed {
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
		position: fixed;
		top: -73px;
		height: 73px;
		padding: 16px 32px;
		background-color: rgba(0,0,0,.8);
		transition: transform 0.3s;
	}
	.h-headWrap {
		@media #{$querySp} {
			/* when the user scrolls down, we hide the header right above the viewport */
			position: fixed;
			top: -64px;
			height: 64px;
			background-color: rgba(0,0,0,.8);
			transition: transform 0.3s;
		}
		@media #{$queryPcTab} {
		}
		.h-logo {
			@media #{$querySp} {
				left: 15px;
			}
			@media #{$queryPcTab} {
			}
			img {
				@media #{$queryPcTab} {
					width: 200px;
					height: auto;
				}
			}
		}
		.h-btnNav__link {
			@media #{$querySp} {
				right: 8px;
			}
			@media #{$queryPcTab} {
			}
		}
	}
}

.h-menuWrap.is-visible {
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
		transform: translate3d(0, 100%, 0);
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
	}
	.h-headWrap {
		@media #{$querySp} {
			/* if the user changes the scrolling direction, we show the header */
			transform: translate3d(0, 99%, 0);
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		}
		@media #{$queryPcTab} {
		}
	}
}

.h-menuWrap.menu-is-open {
	.h-headWrap {
		@media #{$querySp} {
			height: 64px;
			/* add a background color to the header when the navigation is open */
			background-color: rgba(0,0,0,.8);
		}
		@media #{$queryPcTab} {
		}
		.h-logo {
			@media #{$querySp} {
				left: 15px;
			}
			@media #{$queryPcTab} {
			}
		}
		.h-btnNav__link {
			@media #{$querySp} {
				right: 8px;
			}
			@media #{$queryPcTab} {
			}
		}
	}
}

/*----- ハンバーガーボタン -----*/
.h-btnNav__link {
	@media #{$querySp} {
		position: absolute;
		top: 50%;
		right: 8px;
		bottom: auto;
		width: 42px;
		height: 30px;
		background-color: transparent;
		line-height: 30px;
		transform: translateY(-50%);
		@include trans($duration: .3s,$delay: 0s)
	}
	@media #{$queryPcTab} {
		display: none;
	}
}

.h-btnNav__link .cd-menu-icon {
	@media #{$querySp} {
		/* this span is the central line of the menu icon */
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		transform: translateX(-50%) translateY(-50%);
		width: 18px;
		height: 2px;
		background-color: white;
		transition: background-color 0.3s;
		/* these are the upper and lower lines in the menu icon */
	}
	@media #{$queryPcTab} {
	}
}

.h-btnNav__link .cd-menu-icon::before, .h-btnNav__link .cd-menu-icon:after {
	@media #{$querySp} {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: white;
		right: 0;
		transition: transform .3s, top .3s, background-color 0s;
	}
	@media #{$queryPcTab} {
	}
}

.h-btnNav__link .cd-menu-icon::before {
	@media #{$querySp} {
		top: -5px;
	}
	@media #{$queryPcTab} {
	}
}

.h-btnNav__link .cd-menu-icon::after {
	@media #{$querySp} {
		top: 5px;
	}
	@media #{$queryPcTab} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked {
	@media #{$querySp} {
		background-color: rgba(255, 255, 255, 0);
	}
	@media #{$queryPcTab} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked::before, .h-btnNav__link .cd-menu-icon.is-clicked::after {
	@media #{$querySp} {
		background-color: white;
	}
	@media #{$queryPcTab} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked::before {
	@media #{$querySp} {
		top: 0;
		transform: rotate(135deg);
	}
	@media #{$queryPcTab} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked::after {
	@media #{$querySp} {
		top: 0;
		transform: rotate(225deg);
	}
	@media #{$queryPcTab} {
	}
}

/*----- ナビゲーション -----*/
.h-menu {
	@media #{$querySp} {
		/* by default it's hidden - on top of the viewport */
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		@include gradient(#f3b019, #ecd440, horizontal);
		z-index: 100;
		text-align: center;
		padding: 50px 0;
		backface-visibility: hidden;
		overflow: auto;
		/* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
		transform: translateY(-100%);
		transition-property: transform;
		transition-duration: 0.4s;
	}
	@media #{$queryPcTab} {
		display: flex;
	}
}

.no-touch .h-menu a:hover {
	@media #{$querySp} {
		background-color: #e36767;
	}
	@media #{$queryPcTab} {
	}
}

.h-menu.is-visible {
	@media #{$querySp} {
		transform: translateY(0);
	}
	@media #{$queryPcTab} {
	}
}

.h-menu {
	@media #{$querySp} {
		padding: 64px 0 0;
	}
	@media #{$queryPcTab} {
		display: flex;
		align-items: center;
	}
}

.h-menuItem {
	@media #{$querySp} {
		margin: 2.4em 0 .8em;
	}
	@media #{$queryPcTab} {
		margin-left: 56px;
	}
}

.h-menuItem__link {
	@media #{$querySp} {
		display: inline-block;
		padding: .4em 1em;
		border-radius: 0.25em;
		transition: background 0.2s;
		font-size: 1.5rem;
		color: #fff;
		text-shadow:0px 0px 2px #000;
		font-weight: 600;
	}
	@media #{$queryPcTab} {
		text-shadow:0px 0px 2px #000;
		display: inline-block;
		font-weight: 600;
		color: #fff;
		@include trans($duration: .2s,$delay: 0s)
		&:hover {
			color: #000;
			text-shadow:0px 0px 2px #fff;
		}
	}
	&--contact {
		@include borderRadius(50px);
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
			padding: 8px 40px;
			background-color: #f4b029;
			&:hover {
				color: #000;
				background-color: #fff;
			}
		}
	}
}

.is-visible .h-menuItem__link {
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
		&:hover {
			color: #f4b029;
			text-shadow: none;
		}
	}
	&--contact {
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
			&:hover {
				color: #000;
				text-shadow: none;
			}
		}
	}
}












































