@charset "UTF-8";
/* ----------------------------------------------------------------
TOPページのみのスタイル
-----------------------------------------------------------------*/
/* キービジュアル
-----------------------------------------------------------------*/
.p-homeKvWrap {
	width: 100%;
	@include gradient(#f3b019, #ecd440, horizontal);
	@media #{$querySp} {
		height: 120vw;
	}
	@media #{$queryPcTab} {
		height: 450px;
	}
	@media #{$queryPc} {
	}
}

.p-homeKvWrap__inner {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	@media #{$querySp} {
		padding-top: 14vw;
	}
	@media #{$queryPcTab} {
		max-width: 900px;
	}
	@media #{$queryPc} {
	}
}

.p-homeKv__box {
	@media #{$querySp} {
		text-align: center;
	}
	@media #{$queryPcTab} {
		position: absolute;
		top: 106px;
		left: 0;
		padding-left: 50px;
	}
	@media #{$queryPc} {
	}
}

.p-homeKv__head {
	color: #fff;
	@media #{$querySp} {
		font-size: 5vw;
		margin-bottom: 2vw;
	}
	@media #{$queryTab} {
	}
	@media #{$queryPcTab} {
		font-size: 24px;
		margin-bottom: 8px;
	}
	@media #{$queryPc} {
	}
}

.p-homeKv_logo {
	@media #{$querySp} {
		display: inline-block;
		width: 50vw;
		margin-bottom: 3vw;
	}
	@media #{$queryPcTab} {
		width: 200px;
		margin-bottom: 32px;
	}
	@media #{$queryPc} {
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeKv_btn {
	@media #{$querySp} {
		display: inline-block;
		width: 50vw;
	}
	@media #{$queryPcTab} {
		width: 200px;
	}
	@media #{$queryPc} {
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeKv_phone01 {
	position: absolute;
	@media #{$querySp} {
		width: 70vw;
		bottom: -10vw;
		left: 0;
		right: 20vw;
		margin: 0 auto;
	}
	@media #{$queryPcTab} {
		width: 51vw;
		max-width: 490px;
		top: 90px;
		right: 120px;
		padding-right: 10px;
	}
	@media #{$queryPc} {
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeKv_phone02 {
	position: absolute;
	@media #{$querySp} {
		width: 70vw;
		bottom: -10vw;
		left: 20vw;
		right: 0;
		margin: 0 auto;
	}
	@media #{$queryPcTab} {
		width: 51vw;
		max-width: 490px;
		top: 90px;
		right: 0;
		padding-right: 10px;
	}
	@media #{$queryPc} {
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

/* イントロ
-----------------------------------------------------------------*/
.p-homeContentsWrap {
	width: 100%;
	padding: 64px 0;
	@media #{$querySp} {
		padding: 32px 0;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
	&--info {
		padding-top: 120px;
		@media #{$querySp} {
			padding-top: 16vw;
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
	&--use {
		background-color: #FFFCEF;
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeContentsWrap__innter {
	width: 100%;
	margin: 0 auto;
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
		width: 566px;
	}
	@media #{$queryPc} {
		width: 780px;
	}
}

.p-homeLeadWrap {
	margin-bottom: 48px;
	@media #{$querySp} {
		padding: 0 10px;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeLead__logo {
	width: 64px;
	height: auto;
	@media #{$querySp} {
		width: 54px;
		height: auto;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeLead__head {
	margin-bottom: 8px;
	font-size: 2.0rem;
	text-align: center;
	@media #{$querySp} {
		font-size: 1.8rem;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeLead__txt {
	text-align: center;
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

/* 紹介
-----------------------------------------------------------------*/
.p-homeCol {
	width: 100%;
	display: flex;
	@media #{$querySp} {
		padding: 0 10px;
	}
	@media #{$queryPcTab} {
		align-items: center;
	}
	@media #{$queryPc} {
	}
	&--info01 {
		margin-bottom: 48px;
		@media #{$querySp} {
			margin-bottom: 24px;
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
	&--info01 {
		margin-bottom: 48px;
		@media #{$querySp} {
			margin-bottom: 24px;
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
	&--use01 {
		margin-bottom: 48px;
		@media #{$querySp} {
			margin-bottom: 24px;
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeCol__pic {
	@media #{$querySp} {
		width: 20%;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeCol__txt {
	padding-left: 8px;
	@media #{$querySp} {
		width: 80%;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
	&--use {
		padding-left: 16px;
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeInfo__head {
	margin-bottom: 8px;
	font-size: 1.8rem;
	@media #{$querySp} {
		font-size: 1.5rem;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

/* 使い方
-----------------------------------------------------------------*/
.p-homeUse__head {
	margin-bottom: 48px;
	font-size: 3.2rem;
	text-align: center;
	@media #{$querySp} {
		margin-bottom: 32px;
		font-size: 2.4rem;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeUse__head02 {
	margin-bottom: 8px;
	font-size: 2.0rem;
	@media #{$querySp} {
		padding-left: 10px;
		font-size: 1.8rem;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

/* ダウンロード・ナビ
-----------------------------------------------------------------*/
.p-homeDlWrap {
	width: 100%;
	padding: 40px 0;
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeDl__txt {
	text-align: center;
	margin-bottom: 8px;
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeDl_btn {
	text-align: center;
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeFootNav {
	@media #{$querySp} {
		text-align: center;
	}
	@media #{$queryPcTab} {
		display: flex;
		justify-content: center;
		padding: 8px 0;
	}
	@media #{$queryPc} {
	}
}

.p-homeFootNav__item {
	padding: 0 32px;
	@media #{$querySp} {
		margin-bottom: 10px;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.p-homeFootNav__itemLink {
	@media #{$querySp} {
	}
	@media #{$queryPcTab} {
		@include trans($duration: .1s,$delay: 0s)
	}
	@media #{$queryPc} {
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPcTab} {
			color: #f4b029;
		}
		@media #{$queryPc} {
		}
	}
}





















