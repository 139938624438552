@charset "UTF-8";
/* ----------------------------------------------------------------
generalのスタイル
-----------------------------------------------------------------*/
.g-termsHeadWrap {
	width: 100%;
	@include gradient(#f3b019, #ecd440, horizontal);
	display: flex;
	justify-content: center;
	align-items: center;
	@media #{$querySp} {
		height: 30vw;
	}
	@media #{$queryPcTab} {
		height: 200px;
	}
	@media #{$queryPc} {
	}
}

.g-termsHead__txt {
	text-align: center;
	color: #fff;
	font-weight: 600;
	font-size: 3.2rem;
	text-shadow:0px 0px 1px #000;
	@media #{$querySp} {
		font-size: 2.0rem;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}