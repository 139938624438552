@charset "UTF-8";
/* ----------------------------------------------------------------
利用規約ページのみのスタイル
-----------------------------------------------------------------*/
.be-block {
    line-height: 150%;
    word-break: break-all;
    font-size: 1em;
}

.be-block--inr {
    margin: 1em;
}

.be-head-h1 {
    font-size: 1.5em;
    text-align: center;
}

.be-unit {
    margin-bottom: 2em;
}

.be-head-h3 {
    font-size: 1.25em;
}

.be-list-num ol {
    margin: 0;
    padding-left: 1.5em;
}

.be-list-num ol li {
    list-style: decimal;
    margin: 0;
}





















