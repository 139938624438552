@charset "UTF-8";
/* ----------------------------------------------------------------
footerのスタイル
-----------------------------------------------------------------*/
footer {
	margin-top: auto;
}

.f-wrap {
	width: 100%;
	padding: 40px 0;
	background-color: #EEEEEE;
	text-align: center;
	@media #{$querySp} {
		padding: 20px 0;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}

.f-copy {
	font-size: 1.2rem;
	@media #{$querySp} {
		font-size: 1.0rem;
	}
	@media #{$queryPcTab} {
	}
	@media #{$queryPc} {
	}
}


